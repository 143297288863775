import { useEffect } from 'react';

const useStyle = url => {
  useEffect(() => {
    const script = document.createElement('link');

    script.rel = "stylesheet";
    script.href = url;
    
    //script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url])
}

export default useStyle;