import React from "react";

const OficialMatchWidget = ({event, events, eventIndex}) => {
    let shouldHaveDate = true;

    let eventDate = new Date(event.startTime);
    let todayDate = new Date();
    let tomorrowDate = new Date(todayDate.getTime() + 24 * 60 * 60 * 1000);

    let isToday = todayDate.getDate() == eventDate.getDate() && todayDate.getMonth() == eventDate.getMonth() && todayDate.getFullYear() == eventDate.getFullYear();
    let isTomorrow = tomorrowDate.getDate() == eventDate.getDate() && tomorrowDate.getMonth() == eventDate.getMonth() && tomorrowDate.getFullYear() == eventDate.getFullYear();

    let eventWeekday = GetWeekdayName(eventDate);
    let todayWeekday = GetWeekdayName(todayDate);
    let tomorrowWeekday = GetWeekdayName(tomorrowDate);

    if(eventIndex > 0)
    {
        let lastEvent = events[eventIndex - 1];

        let lastEventDate = new Date(lastEvent.startTime);
        let lastEventWeekday = GetWeekdayName(lastEventDate);

        shouldHaveDate = eventWeekday != lastEventWeekday || eventDate.getDate() != lastEventDate.getDate();
    }

    let weekDiff = getWeeksDiff(todayDate, eventDate);
    let monthName = eventDate.toLocaleString('default', { month: 'long' });

    return (
        <div className={`OficialMatchWidget ${shouldHaveDate ? "day-section-start" : ""}`}>
            <div className="OficialMatch future">
                <div className="date">{shouldHaveDate ? todayWeekday == eventWeekday && isToday ? "Hoje" : tomorrowWeekday == eventWeekday && isTomorrow ? "Amanhã" : weekDiff <= 0.9 ? eventWeekday : `${eventDate.getDate()} ${monthName}` : ""}</div>
                <div className="teams">
                    {event.match != undefined ? event.match.teams.map((team, index) => (
                        <div className="team">
                            <img src={`https://am-a.akamaihd.net/image?resize=36:&f=${team.image}`} alt="" />
                            <span className="code">{team.code}</span>
                        </div>
                    )) : ""}
                </div>
                <div className="time">
                    <span className="hour">{eventDate.getHours()}</span>
                    <span className="minute">:{(eventDate.getMinutes() < 10 ? "0" : "") + eventDate.getMinutes()}</span>
                </div>
            </div>
        </div>
    );
};

function GetWeekdayName(date)
{
    let dateWeekdayNum = date.getDay() % 7;
    switch(dateWeekdayNum)
    {
        case 0:
            return "Domingo";
        case 1:
            return "Segunda";
        case 2:
            return "Terça";
        case 3:
            return "Quarta";
        case 4:
            return "Quinta";
        case 5:
            return "Sexta";
        case 6:
            return "Sábado";
    }
}

function getWeeksDiff(startDate, endDate) {
    const msInWeek = 1000 * 60 * 60 * 24 * 7;
  
    return Math.abs(endDate - startDate) / msInWeek;
  }
 
export default OficialMatchWidget;