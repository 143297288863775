import React, { useEffect, useState } from "react";

const Timer = ({timestamp}) => {
    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    let now = new Date().getTime();
    let totalTime = 0;
    let matchHasStarted = false;

    totalTime = now - (timestamp <= 0 ? now : timestamp);
    matchHasStarted = totalTime != now;

    return (
        <>{fmtMSS(((matchHasStarted ? totalTime : now) / 1000).toFixed(0))}</>
    );
};

function fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}
 
export default Timer;