import React from "react";

const Opgg = ({player}) => {
    let nick = "";
    let url = "";

    if(player.accounts.length > 1)
    {
        player.accounts.forEach(account => {
            nick += account.gameName == undefined ? account.summonerName + "," : account.gameName + "%23" + account.tagLine + ",";
        });
        url = `https://www.op.gg/multisearch/${player.accountRegion}?summoners=${nick}`;
    } else if(player.accounts.length == 1) {
        nick = player.accounts[0].gameName == undefined ? player.accounts[0].summonerName : player.accounts[0].gameName + "-" + player.accounts[0].tagLine;
        url = `http://${player.accountRegion}.op.gg/summoner/userName=${nick}`
    }

    return (
        player.accountRegion != undefined && player.accountRegion == "CH" ? "" : (
        <div className="div-player-social">
            <a href={url} target="_blank">
                <img className="svg-opgg" src="https://victti-dev.com.br/public/images/icons/opgg.svg" />
            </a>
        </div>
        )
    );
};

export default Opgg;