import React, { useEffect, useState, useRef } from "react";
import OficialMatchWidget from "./OficialMatchWidget";
import "../../styles/OficialGamesWidget.css";

const eventListURL = "https://esports-api.lolesports.com/persisted/gw/getEventList?hl=pt-BR&leagueId=";
const apiKey = "0TvQnueqKa5mxJntVWt0w4LpLfEkrV1Ta8rQBb9Z";

const OficialGamesWidget = ({leagueId}) => {
    const [eventList, setEventList] = useState(undefined);
    // 98767975604431411 - Worlds
    // 98767991325878492 - MSI
    // 98767991332355509 - CBLOL
    // 105549980953490846 - CBLOL Academy
    // 98767991310872058 - LCK
    
    useEffect(() => {
        fetch(eventListURL + leagueId, {headers: {"x-api-key": apiKey}}).then(
          response => response.json()
        ).then(
          async data => {
            setEventList(data)
          }
        )
  
        const eventListInterval = setInterval(() => {
          fetch(eventListURL + leagueId, {headers: {"x-api-key": apiKey}}).then(
            response => response.json()
          ).then(
            async data => {
              setEventList(data)
            }
          );
        }, 60000);
        return () => {
          clearInterval(eventListInterval);
        };
    }, []);

    return (
        eventList != undefined ? (
            <div className="OficialGamesWidget">
                <div className="event-list">
                    {eventList.data.esports.events.map((event, index, events) => (
                        <OficialMatchWidget key={`oficial-game-${index}`} event={event} events={events} eventIndex={index} />
                    ))}
                </div>
            </div>
        ) : ""
    );
};
 
export default OficialGamesWidget;