import React, { useState, useRef } from "react";
import PlayerRank from './Rank';
import PlayerWinrate from './Winrate';
import MatchHistory from './MatchHistory';
import Socials from './Socials';

const DesktopPlayerRow = ({playerIndex, player, allPlayers, riotProps, specOrdered}) => {
    const [isMhExpanded, setIsMhExpanded] = useState(false);
    const pointer = useRef({x: 0, y: 0});

    const onMouseDown = (e) => {
        pointer.current = {x: e.clientX, y: e.clientY};
    }

    const onMouseUp = (e) => {
        const {x, y} = pointer.current;
        if (Math.abs(e.clientX - x) < 10 && Math.abs(e.clientY - y) < 10) {
            onRowClick(e);
        }
    }

    const onRowClick = (event) => {
        if (event.button != 0)
            return;

        if(event.target != undefined && ((event.target.offsetParent != undefined && (event.target.offsetParent.className.includes("div-player-social") || event.target.offsetParent.className.includes("tooltip"))) || event.target.tagName == "A"))
            return;

        if(riotProps != undefined && riotProps.pageName == "bootcamp" && player.region != "BR")
            return;

        setIsMhExpanded(!isMhExpanded)
    }

    return (
        <>
            <tr className={`player-row bg-${player.team}`} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
                <td className="align-center semi-bold text-shadow">{riotProps != undefined && riotProps.pageName == "bootcamp" ? player.region.toUpperCase() + " " + (player.region != "" ? player.tier : "") : playerIndex + 1}</td>
                <td className="td-role align-center"><img className="img-role" src={`https://victti-dev.com.br/public/images/lanes/${player.role}.png`} title={player.role} /></td>
                <td className="align-left"><div className="div-player semi-bold text-shadow">{player.isWorlds ? <img src="https://victti-dev.com.br/public/images/icons/worlds.png" /> : ""}{player.name}</div></td>
                <td>
                    <div className="div-ranks">
                    {player.accounts.map((account, index) => (
                        <PlayerRank key={`player-${player.summonerName}-${index}`} accountData={account} playerName={player.name} allPlayers={allPlayers} riotProps={riotProps} specOrdered={specOrdered} />
                    ))}
                    </div>
                </td>
                <td><PlayerWinrate accountsData={player.accounts} /></td>
                <td><Socials isMobile={false} player={player} /></td>
            </tr>
            {player.accountRegion != undefined && player.accountRegion == "CH" ? "" : (<MatchHistory isMobile={false} isMhExpanded={isMhExpanded} player={player} riotProps={riotProps} />)}
        </>
    );
};

const MobilePlayerRow = ({playerIndex, player, allPlayers, riotProps, specOrdered}) => {
    const [isMhExpanded, setIsMhExpanded] = useState(false);
    const pointer = useRef({x: 0, y: 0});

    const onRowClick = (event) => {
        if(event.target != undefined && ((event.target.offsetParent != undefined && (event.target.offsetParent.className.includes("div-player-social") || event.target.offsetParent.className.includes("tooltip"))) || event.target.tagName == "A"))
            return;

        if(riotProps != undefined && riotProps.pageName == "bootcamp" && player.region != "BR")
            return;

        setIsMhExpanded(!isMhExpanded)
    }

    return (
        <>
            <div className={`fake-table-row`} onClick={onRowClick}>
                <div className="fake-table-top">
                    <div className="fake-table-ranking align-center semi-bold">{riotProps != undefined && riotProps.pageName == "bootcamp" ? player.region.toUpperCase() + " " + (player.region != "" ? player.tier : "") : playerIndex + 1}</div>
                    <div className="fake-table-role align-center">
                        {player.team != "" ? <img className="img-role" src={`https://victti-dev.com.br/public/images/teams/${player.team}.${player.team != "STREAMER" ? "png" : "svg"}`} title={player.team} /> : ""}
                        <img className="img-role" src={`https://victti-dev.com.br/public/images/lanes/${player.role}.png`} title={player.role} />
                    </div>
                    <div className="fake-table-player semi-bold text-shadow">{player.isWorlds ? <img src="https://victti-dev.com.br/public/images/icons/worlds.png" /> : ""}{player.name}</div>
                    <Socials isMobile={true} player={player} />
                </div>
                    <div className="fake-table-bottom">
                        <div className="fake-table-ranks">{ player.accounts.map((account, index) => (<PlayerRank key={`player-${player.summonerName}-${index}`} accountData={account} playerName={player.name} allPlayers={allPlayers} riotProps={riotProps} specOrdered={specOrdered} />)) }</div>
                        <div className="fake-table-winrate"><PlayerWinrate accountsData={player.accounts} />
                    </div>
                </div>
            </div>
            <MatchHistory isMobile={true} isMhExpanded={isMhExpanded} player={player} riotProps={riotProps} />
        </>
    );
};
 
export {DesktopPlayerRow, MobilePlayerRow};