import React, { useState } from "react";

const PlayerWinrate = ({accountsData}) => {
    let wins = 0;
    let loses = 0;

    for(let account of accountsData)
    {
        wins += account.wins;
        loses += account.losses;
    }

    let total = wins + loses;
    let percent = (wins / total) * 100;
    if(Number.isNaN(percent))
        percent = 0;

    return (
        <>
        <div className="div-winrate-top bold">
            <div className="div-winrate-left">{wins}V-{loses}D</div>
            <div className="div-winrate-right">{percent.toFixed(1).replace(/[.,]0$/, "")}%</div>
        </div>
        <div className="div-winrate-bottom"><progress className={percent >= 50 ? "blue" : "red"} value={percent} max={100}></progress></div>
        </>
    );
};
 
export default PlayerWinrate;