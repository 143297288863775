import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Lolpros from './pages/lolpros';
import Bootcamp from './pages/bootcamp';
import Rewind from './pages/rewind';
import BettingStats from './pages/betting-stats'
import MultiChat from './pages/multichat';
import MultiStream from './pages/multistream'
import MSI from './pages/msi';

function App() {
    const patchNumber = "14.14.1";

    return (
        <Router>
            <Navbar />
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/lolpros/:region' element={<><Lolpros patchNumber={patchNumber} /><Footer /></>} />
                <Route path='/bootcamp' element={<><Bootcamp patchNumber={patchNumber} /><Footer /></>} />
                <Route path='/msi' element={<><MSI patchNumber={patchNumber} /><Footer /></>} />
                <Route path='/rewind' element={<Rewind />} />
                <Route path='/bet-stats' element={<BettingStats />} />
                <Route path='/multichat' element={<MultiChat />} />
                <Route path="/multistream/:stream1/:stream2?/:stream3?/:stream4?/:stream5?/:stream6?/:stream7?/:stream8?/:stream9?" element={<MultiStream />} />
            </Routes>
        </Router>
    );
}

export default App;
