import React, {useEffect, useState} from 'react';
import "../styles/rewind.css";

const Rewind = () => {
    const [input, setInputData] = useState("");
    const [riotID, setRiotID] = useState(["", ""]);
    const [region, setRegion] = useState("BR");
    const [deep, setDeep] = useState(0);
    const [userData, setUserData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
		document.title = "Rewind";
    }, []);

    const handleInputChange = (event) => {
        setInputData(event.target.value);

        let riotID = event.target.value.split("#");
        setRiotID(riotID);
    }

    const handleRegionChange = (event) => {
        setRegion(event.target.value);
    }

    const search = async () => {
        try {
            if(riotID.length < 2)
                return;
            
            setIsLoading(true);
            setUserData(undefined);
    
            const response = await fetch('/api/v1/league/name-history/' + region + '/' + riotID[0] + "/" + riotID[1]);

            if(!response.ok)
            {
                throw new Error(`Error! status: ${response.status}`);
            }

            const result = await response.json();

            setUserData(result);
        } catch(ex)
        {
    
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="div-content">
            <div className="user-search">
                <input type="search" placeholder="Procurar por um Riot ID (exemplo#1234)" maxLength="50" value={input} onChange={handleInputChange} />
                <select className="region-select" onChange={handleRegionChange} title="Região">
                    <option>BR</option>
                    <option>EUN</option>
                    <option>EUW</option>
                    <option>JP</option>
                    <option>KR</option>
                    <option>LA1</option>
                    <option>LA2</option>
                    <option>NA</option>
                    <option>OC</option>
                    <option>PH</option>
                    <option>RU</option>
                    <option>SG</option>
                    <option>TH</option>
                    <option>TR</option>
                    <option>TW</option>
                    <option>VN</option>
                </select>
                <input list="deep-options" className="deep-select" title="Quantas partidas a fundo procurar (Limitado a 1000)" value={deep} onChange={event => setDeep(event.target.value.replace(/\D/,''))} />
                <datalist id='deep-options'>
                    <option>0</option>
                    <option>50</option>
                    <option>100</option>
                    <option>150</option>
                    <option>200</option>
                    <option>250</option>
                </datalist>
                <button className={`update-button ${deep > 0 ? "" : "blocked"}`} type="button" onClick="" title="Atualizar">
                    <svg fill={deep > 0 ? "#fff" : "#555"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.155 14.155">
                        <g>
                            <path d="M12.083,1.887c-0.795-0.794-1.73-1.359-2.727-1.697v2.135c0.48,0.239,0.935,0.55,1.334,0.95
                                c1.993,1.994,1.993,5.236,0,7.229c-1.993,1.99-5.233,1.99-7.229,0c-1.991-1.995-1.991-5.235,0-7.229
                                C3.466,3.269,3.482,3.259,3.489,3.25h0.002l1.181,1.179L4.665,0.685L0.923,0.68l1.176,1.176C2.092,1.868,2.081,1.88,2.072,1.887
                                c-2.763,2.762-2.763,7.243,0,10.005c2.767,2.765,7.245,2.765,10.011,0C14.844,9.13,14.847,4.649,12.083,1.887z"/>
                        </g>
                    </svg>
                </button>
                <button className="search-button" type="button" onClick={search} title="Procurar">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"/></svg>
                </button>
            </div>

            {userData != undefined || isLoading ? (
                <div className="wrap-table">
                    <div className="table">
                        <div className="row header">
                            <div className="cell">Nick</div>
                            <div className="cell">Data Aproximada</div>
                        </div>
                        {userData != undefined ? (
                            userData.map((value) => (
                                <div className="row">
                                    <div className="cell">{value.summonerName}</div>
                                    <div className="cell">{value.date}</div>
                                </div>
                            ))
                        ) : ""}

                    </div>
                </div>
            ): ""}

            {isLoading ? (
                <div><img className="loading-icon" src="https://victti-dev.com.br/public/images/loading.webp" /></div>
            ) : ""}
        </div>
    );
};
 
export default Rewind;