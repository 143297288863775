const GetRiotProps = async (pageName, patchNumber) => {
    let output = {};

    output.pageName = pageName;
    output.patchNumber = patchNumber;
    output.championsJSON = await fetch(`https://ddragon.leagueoflegends.com/cdn/${patchNumber}/data/en_US/champion.json`).then(response => response.json());
    output.queuesJSON = await fetch(`https://static.developer.riotgames.com/docs/lol/queues.json`).then(response => response.json());

    return output;
}

export default GetRiotProps;