import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import "../styles/multichat.scoped.css";

const MultiChat = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [youtubeID, setYoutubeID] = useState("");

    let twitchUser = searchParams.get("twitch") ? searchParams.get("twitch") : "baiano";
    let youtubeChannel = searchParams.get("youtubeId") ? searchParams.get("youtubeId") : searchParams.get("youtube") ? !searchParams.get("youtube").startsWith("@") ? `https://www.youtube.com/channel/${searchParams.get("youtube")}` : `https://www.youtube.com/${searchParams.get("youtube")}` : "https://www.youtube.com/channel/UCEEHh7E_kmK1Ym8ogI0kK6g";

    useEffect(() => {
        document.title = "Multichat";
    });

    // Limpa tela se necessário
    useEffect(() => {
        let html = document.getElementsByTagName("html")[0];
        if(html)
            html.style = "height: 100%";

        let body = document.getElementsByTagName("body")[0];
        if(body)
            body.style = "height: 100%";

        let root = document.getElementById("root");
        if(root)
            root.style = "height: 100%";

        let nav = document.getElementsByTagName("nav")[0];
        if(nav)
            nav.style = "display: none";
    });

    // Pega a live atual do youtube
    useEffect(() => {
        if(searchParams.get("youtubeId"))
        {
            setYoutubeID(youtubeChannel);
            return;
        }

        fetch(`https://corsproxy.io/?${youtubeChannel}?t=${Date.now()}`, {}).then((resp) => {
            return resp.text();
        }).then((html) => {
            let videoId = pegaStringSafe(html, '{"videoRenderer":{"videoId":"', '","thumbnail":');

            setYoutubeID(videoId);
        }).catch((err) => {
            fetch(`/api/v1/youtube/getLive/${encodeURIComponent(youtubeChannel)}`).then(resp => resp.text()).then(videoId => setYoutubeID(videoId));
        })
    })

    let embed_domain = window.location.hostname;

    return (
        <div class="chat-containers">
            <iframe src={`https://twitch.tv/embed/${twitchUser}/chat?no-mobile-redirect=true&parent=${embed_domain}&darkpopout=true`} width={'50%'} height={'100%'} />
            {(youtubeID != "" ? <iframe src={`https://gaming.youtube.com/live_chat?v=${youtubeID}&embed_domain=${embed_domain}`} width={'50%'} height={'100%'} /> : "")}
        </div>
    );
};

function pegaStringSafe(str, first_character, last_character) {
    if (str.match(first_character + "(.*?)" + last_character) == null) {
        return null;
    } else {
        let new_str = str.match(first_character + "(.*?)" + last_character)[1].trim()
        return new_str;
    }
}

export default MultiChat;