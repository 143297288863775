import React, { useState } from "react";
import Opgg from "./Opgg";

const Socials = ({isMobile, player}) => {
    return (
        <div className={!isMobile ? "div-player-socials" : "fake-table-socials"}>
            {Object.entries(player.socials).map(([socialName, socialAcc]) => (
                (socialAcc != "" ? (
                    <div key={`${player.name}-${socialName}`} className={`div-player-social div-${socialName} ${socialName == "twitch" ? player.isLive ? "live" : "" : ""}`}>
                        <a href={socialName == "leaguepedia" ? `https://lol.fandom.com/wiki/${socialAcc}` : `https://${socialName}.${socialName == "twitch" ? "tv" : "com"}/${socialAcc}`} target="_blank">
                            <img className={`svg-${socialName}`} src={`https://victti-dev.com.br/public/images/icons/${socialName}.svg`} />
                        </a>
                    </div>
                ) : "")

            ))}
            <Opgg player={player} />
        </div>
    );
};

export default Socials;