import React, { useState, useEffect } from "react";

const Match = ({isMobile, match, riotProps}) => {
    let teamsText = "";

    match.p.b.map(player => {
        let team = player.t.replace("FreeAgent", "F/A");
        teamsText += team != "" ? `${team} ${player.n2} - ${player.c}\r\n` : "";
    });

    match.p.r.map(player => {
        let team = player.t.replace("FreeAgent", "F/A");
        teamsText += team != "" ? `${team} ${player.n2} - ${player.c}\r\n` : "";
    });

    return (!isMobile ? (
        <div className={`match ${match.w ? "victory" : "defeat"}`}>
            <div className="match-general">
                <div className="match-general-top">Ranqueada Solo<br />há {match.dt}</div>
                <div className="match-general-bottom">{match.w ? "Vitória" : "Derrota"}<br />{match.t}</div>
            </div>
            <div className="match-player-stats">
                <div className="match-player-stats-top">
                    <div className="match-player-champion">
                        <div className="match-player-champion-icon">
                            <img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/champion/${match.c}.png`} title="${match.c}"/>
                        </div>
                        <div className="match-player-champion-spells">
                            {match.s.map(spell => (
                                <div key={`${match.id}-spell-${spell}`} className="spell"><img src={`http://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/spell/${spell}.png`} /></div>
                            ))}
                        </div>
                        <div className="match-player-champion-runes">

                        </div>
                    </div>
                    <div className="match-player-champion-kda">{match.k} / {match.d} / {match.a}<br />{match.kda} KDA</div>
                </div>
                <div className="match-player-stats-bottom">
                    <div className="player-items">
                        <ul>
                            {match.i.map((item, index) => (
                                <li key={`${match.id}-item-${index}`}><div>{item != 0 ? <img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/item/${item}.png`}/> : ''}</div></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="match-player-overall">
                <div className="match-player-overall-top">
                    P/Abate: {match.kp}%<br />
                    Sentinelas de Controle: {match.cw}<br />
                    CS {match.cs} ({match.csm})
                </div>
                <div className="match-player-overall-bottom">
                    {match.r != undefined ? <span className="replay-file"><a target="_blank" href={`/api/v1/league/replay/${match.r.pid}/${match.r.gid}`}>&#8250; Replay</a></span> : ""}
                    {match.r != undefined ? <button className="copy-teams" title="Copie quem é PRO na partida" onClick={() => {navigator.clipboard.writeText(teamsText)}}><i className="copyToClipboard-icon replay"></i></button> : ""}
                </div>
            </div>
            <div className="match-players">
                <div className="match-players-blue">
                    <ul>
                    {match.p.b.map(player => (
                        <li key={`${match.id}-player-${player.c}`}><div className={player.t != "" ? `tooltip-team-participant bg-${player.t} tooltip-team-blue-bg` : ""}><img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/champion/${player.c}.png`} title={player.c} />{player.t != "" ? <a target="_blank" href={`/${riotProps.pageName}?search=${player.n2}`} >{player.n}{player.t != "" && player.n.toUpperCase() != player.n2.toUpperCase() ? " - " + player.n2 : ""}</a> : player.n}</div></li>
                    ))}
                    </ul>
                </div>
                <div className="match-players-red">
                    <ul>
                    {match.p.r.map(player => (
                        <li key={`${match.id}-player-${player.c}`}><div className={player.t != "" ? `tooltip-team-participant bg-${player.t} tooltip-team-blue-bg` : ""}><img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/champion/${player.c}.png`} title={player.c} />{player.t != "" ? <a target="_blank" href={`/${riotProps.pageName}?search=${player.n2}`} >{player.n}{player.t != "" && player.n.toUpperCase() != player.n2.toUpperCase() ? " - " + player.n2 : ""}</a> : player.n}</div></li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>) : (
            <div className={`match ${match.w ? "victory" : "defeat"}`}>
                <div className="mobile-match-top">
                    <div className="match-general">
                        <div className="match-general-bottom">{match.w ? "Vitória" : "Derrota"} {match.t}</div>
                        <div className="match-general-top">Ranqueada Solo há {match.dt}</div>
                    </div>
                </div>
                <div className="mobile-match-bottom">
                    <div className="match-player-stats">
                        <div className="match-player-champion">
                            <div className="match-player-champion-icon">
                                <img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/champion/${match.c}.png`} title="${match.c}"/>
                            </div>
                            <div className="match-player-champion-spells">
                                {match.s.map(spell => (
                                    <div key={`${match.id}-spell-${spell}`} className="spell"><img src={`http://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/spell/${spell}.png`} /></div>
                                ))}
                            </div>
                            <div className="match-player-champion-runes">

                            </div>
                        </div>
                        <div className="match-player-champion-kda">{match.k} / {match.d} / {match.a}<br />{match.kda} KDA</div>
                    </div>
                    <div className="match-player-overall">
                        P/Abate: {match.kp}%<br />
                        Sentinelas de Controle: {match.cw}<br />
                        CS {match.cs} ({match.csm})
                    </div>
                    <div className="player-items">
                        <ul>
                            {match.i.map((item, index) => (
                                <li key={`${match.id}-item-${index}`}><div>{item != 0 ? <img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/item/${item}.png`}/> : ''}</div></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    );
};
 
export default Match;