import React, {useEffect, useRef} from 'react';
import "../styles/index.css";

const Home = () => {
    useEffect(() => {
		document.title = "Victor Cesar";
    }, []);

    return (
        <div className="div-content">
            <div className="div-top">
                VICTOR CESAR
            </div>
            <div className="div-bottom">
                <a href="https://www.linkedin.com/in/victti" target="_blank"><img src="https://img.shields.io/badge/Linkedin-0e76a8?style=for-the-badge&logo=linkedin&logoColor=white" target="_blank" /></a>
                <a href="https://www.github.com/victti/" target="_blank"><img src="https://img.shields.io/badge/Github-212529?style=for-the-badge&logo=github&logoColor=white" target="_blank" /></a>
                <a href="https://www.twitter.com/_victti" target="_blank"><img src="https://img.shields.io/badge/Twitter-1DA1F2?style=for-the-badge&logo=twitter&logoColor=white" target="_blank" /></a>
                <a className="a-gmail" href="mailto:contato@victti-dev.com.br"><img src="https://img.shields.io/badge/-Gmail-EA4335?style=for-the-badge&logo=gmail&logoColor=white" target="_blank" /></a>
            </div>
        </div>
    );
};
 
export default Home;