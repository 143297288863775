import React, { useEffect, useState } from "react";
import {BrowserView} from 'react-device-detect';
import Timer from './Timer';

const Spectator = ({isHovering, specData, allPlayers, riotProps, specOrdered}) => {
    let inGame = specData != null && specData.status == undefined;

    let teams = {};
    let teamsText = "";

    if(inGame)
    {
        specData.participants.forEach(participant => {
            if(teams[participant.teamId] == undefined)
                teams[participant.teamId] = [];

            let teamId = participant.teamId;

            let summonerName = participant.summonerName != undefined ? participant.summonerName : "";
            let championId = participant.championId;
            
            let championName = "";

            if(riotProps != undefined)
            {
                for(let champEntry in riotProps.championsJSON.data)
                {
                    let championValue = riotProps.championsJSON.data[champEntry];

                    if(parseInt(championValue.key) != championId)
                        continue;

                    championName = championValue.id;
                    break;
                }
            }

            let order = 0;

            if(specOrdered != undefined)
            {
                // Não sei pq não funciona sem esses loop depois que da F5
                for(let [gameId, orderedData] of Object.entries(specOrdered))
                {
                    if(gameId != specData.gameId)
                        continue;

                    for(let [team, teamData] of Object.entries(orderedData))
                    {
                        if(team != teamId)
                            continue;
                        
                        for(let role in teamData)
                        {
                            if(championId != teamData[role])
                                continue;
    
                            switch (role) {
                                case "TOP":
                                    order = 1;
                                    break;
                                case "JUNGLE":
                                    order = 2;
                                    break;
                                case "MIDDLE":
                                    order = 3;
                                    break;
                                case "BOTTOM":
                                    order = 4;
                                    break;
                                case "UTILITY":
                                    order = 5;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            }

            let sumTrimmed = summonerName.trim();

            let isPro = allPlayers[sumTrimmed] != undefined;
            let name = isPro ? allPlayers[sumTrimmed].name : undefined;
            let team = isPro ? allPlayers[sumTrimmed].team : undefined;

            teams[teamId].push({order, teamId, summonerName: summonerName.split("#")[0], championId, championName, isPro, name, team});
        });
    }

    if(teams[100] != undefined)
    {
        teams[100].forEach(participant => {
            if(participant.isPro)
            {
                let team = participant.team.replace("FreeAgent", "F/A");
                teamsText += `${team} ${participant.name} - ${participant.championName}\r\n`;
            }
        });
    }

    if(teams[200] != undefined)
    {
        teams[200].forEach(participant => {
            if(participant.isPro)
            {
                let team = participant.team.replace("FreeAgent", "F/A");
                teamsText += `${team} ${participant.name} - ${participant.championName}\r\n`;
            }
        });
    }

    return isHovering && inGame == true && (teams[100] != undefined || teams[200] != undefined) ? (
        <span className="tooltip">
            <div className="ingame">EM JOGO</div>
            <div className="ingame-mode">{`${GetQueueByID(riotProps, specData.gameQueueConfigId).map} - ${GetGameModeByQueueId(specData.gameQueueConfigId)}`}</div>
            <div className="ingame-timestamp"><Timer timestamp={specData.gameStartTime} /></div>
            <div className="tooltip-teams">
                <div className="tooltip-team">
                    {teams[100] != undefined ? teams[100].map(participant => (
                        <div key={participant.championId} style={{order: participant.order}} className={`tooltip-team-participant flex-left ${participant.isPro ? "bg-" + participant.team + " tooltip-team-blue-bg" : ""}`}>
                            <img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/champion/${participant.championName}.png`}/>
                            <span className={`tooltip-team-participant-name t${participant.teamId}${participant.isPro ? " ingame" : ""}`}>{participant.summonerName}<br />{participant.isPro ? participant.name : ""}</span>
                        </div>
                    )) : ""}
                </div>
                <div className="tooltip-team">
                    {teams[200] != undefined ? teams[200].map(participant => (
                        <div key={participant.championId} style={{order: participant.order}} className={`tooltip-team-participant flex-right ${participant.isPro ? "bg-" + participant.team + " tooltip-team-red-bg" : ""}`}>
                            <span className={`tooltip-team-participant-name t${participant.teamId}${participant.isPro ? " ingame" : ""}`}>{participant.summonerName}<br />{participant.isPro ? participant.name : ""}</span>
                            <img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/champion/${participant.championName}.png`}/>
                        </div>
                    )) : ""}
                </div>
            </div>
            <BrowserView className="ingame-buttons">
                <span className="spectator-file" title="Assista o jogo ao vivo no seu client"><a href={`https://victti-dev.com.br/api/v1/league/spectate/${specData.platformId}/${specData.gameId}`}>&#8250; Assistir</a></span>
                <button className="copy-teams" title="Copie quem é PRO na partida" onClick={() => {navigator.clipboard.writeText(teamsText)}}><i className="copyToClipboard-icon spec"></i></button>
            </BrowserView>
            <div>*Dados atualizam a cada ~5 minutos</div>
        </span>
        ) : "";
};

function GetQueueByID(riotProps, queueId)
{
    console.log(queueId);
    switch(queueId)
    {
        case 1100:
            return { queueId, "map": "Teamfight Tactics", "description": "TFT Ranqueada", "notes": "??" }
        case 1130:
            return { queueId, "map": "Teamfight Tactics", "description": "TFT Batalha Frenética", "notes": "??" }
        case 1160:
            return { queueId, "map": "Teamfight Tactics", "description": "TFT Dupla Dinâmica", "notes": "??" }
    }

    for(let queue of riotProps.queuesJSON)
    {
        if(queue.queueId == queueId)
            return queue;
    }
}

function GetGameModeByQueueId(id)
{
  switch(id)
  {
    case 400:
      return "Normal Draft"
    case 420:
      return "Ranqueada Solo";
    case 430:
      return "Normal as Cegas";
    case 440:
      return "Ranqueada Flex";
    case 450:
      return "ARAM";
    case 1100:
        return "Ranqueada";
    case 1130:
        return "Batalha Frenética";
    case 1160:
      return "Dupla Dinâmicas";
    case 1300:
        return "Modo Rotativo";
    case 1900:
        return "URF";
    default:
      return "";
  }
}

export default Spectator;