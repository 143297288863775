import React, {useEffect, useState} from 'react';
import "../styles/betting.scoped.css";
import "../styles/tailwind.scoped.css";

import { Tooltip } from 'flowbite-react';

const BettingStats = () => {
    //require("../styles/tailwind.css");

    const queryParameters = new URLSearchParams(window.location.search)
    const leaguesParam = queryParameters.get("leagues") != undefined ? queryParameters.get("leagues") : "";

    const [locked, setLocked] = useState(false);
    const [leagues, setLeagues] = useState(undefined);
    const [leagueList, setLeagueList] = useState(undefined)
    const [data, setData] = useState([]);
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(-1);
    const [jsonData, setJsonData] = useState([]);

    useEffect(() => {
        document.title = "Estatísticas de Torneio";

        if(leaguesParam == "")
        {
            setLeagues([]);
            return;
        }
        
        setLeagues(leaguesParam.split(","));
    }, [])

    useEffect(() => {
        if(leagueList == undefined)
        {
            fetch("/api/v1/bayes-proxy/tournaments/").then(resp => resp.json()).then(json => {
                let newLeagueList = [""];

                for(let entry of json.cargoquery)
                {
                    newLeagueList.push(entry.title.OverviewPage.replaceAll(" ", "_"));
                }

                setLeagueList(newLeagueList);
            });
        }
        
        if(leagues == undefined)
            return;

        setLocked(true);

        fetch("/api/v1/bayes-proxy/overall/" + encodeURIComponent(leagues.join(","))).then(
            response => response.json()
        ).then(
            async data => {
                setJsonData(data);
                setLocked(false);
            }
        ).catch(ex => {
            setLocked(false);
        })

        UpdateUrl(leagues);
    }, [leagues]);

    const columns = {
        "Time": { value: "team", tooltip: "", sorter: (a, b) => sortOrder ? b.team.localeCompare(a.team) : a.team.localeCompare(b.team) },
        "Jogos": { value: "games", tooltip: "", sorter: (a, b) => sortOrder ? a.games - b.games : b.games - a.games },
        "Winrate": { value: "winrate", tooltip: "", sorter: (a, b) => sortOrder ? parseFloat(a.winrate) - parseFloat(b.winrate) : parseFloat(b.winrate) - parseFloat(a.winrate) },
        "Winrate Blue": { value: "winrateBlue", tooltip: "Porcentagem de vitória no lado azul", sorter: (a, b) => sortOrder ? parseFloat(a.winrateBlue) - parseFloat(b.winrateBlue) : parseFloat(b.winrateBlue) - parseFloat(a.winrateBlue) },
        "Winrate Red": { value: "winrateRed", tooltip: "Porcentagem de vitória no lado vermelho", sorter: (a, b) => sortOrder ? parseFloat(a.winrateRed) - parseFloat(b.winrateRed) : parseFloat(b.winrateRed) - parseFloat(a.winrateRed) },
        "Tempo": { value: "gameTime", tooltip: "Média de Tempo", sorter: (a, b) => sortOrder ? b.gameTime.localeCompare(a.gameTime) : a.gameTime.localeCompare(b.gameTime) },
        "1° Abate": { value: "fb%", tooltip: "Porcentagem de vezes que o time pega First Blood", sorter: (a, b) => sortOrder ? parseFloat(a["fb%"]) - parseFloat(b["fb%"]) : parseFloat(b["fb%"]) - parseFloat(a["fb%"]) },
        "1° Larva": { value: "fvg%", tooltip: "Porcentagem de vezes que o time pega a primeira Vastilarva", sorter: (a, b) => sortOrder ? parseFloat(a["fvg%"]) - parseFloat(b["fvg%"]) : parseFloat(b["fvg%"]) - parseFloat(a["fvg%"]) },
        "1° Arauto": { value: "frh%", tooltip: "Porcentagem de vezes que o time pega o Arauto", sorter: (a, b) => sortOrder ? parseFloat(a["frh%"]) - parseFloat(b["frh%"]) : parseFloat(b["frh%"]) - parseFloat(a["frh%"]) },
        "1° Torre": { value: "ft%", tooltip: "Porcentagem de vezes que o time pega a primeira torre", sorter: (a, b) => sortOrder ? parseFloat(a["ft%"]) - parseFloat(b["ft%"]) : parseFloat(b["ft%"]) - parseFloat(a["ft%"]) },
        "1° Dragão": { value: "fd%", tooltip: "Porcentagem de vezes que o time pega o primeiro Dragão", sorter: (a, b) => sortOrder ? parseFloat(a["fd%"]) - parseFloat(b["fd%"]) : parseFloat(b["fd%"]) - parseFloat(a["fd%"]) },
        "1° Barão": { value: "fnash%", tooltip: "Porcentagem de vezes que o time pega o primeiro Barão", sorter: (a, b) => sortOrder ? parseFloat(a["fnash%"]) - parseFloat(b["fnash%"]) : parseFloat(b["fnash%"]) - parseFloat(a["fnash%"]) },
        "Ouro": { value: "gold", tooltip: "Média de Ouro do time por partida", sorter: (a, b) => sortOrder ? parseFloat(a.gold) - parseFloat(b.gold) : parseFloat(b.gold) - parseFloat(a.gold) },
        "Abates": { value: "kills", tooltip: "Média de Abates do time por partida", sorter: (a, b) => sortOrder ? a.kills - b.kills : b.kills - a.kills },
        "Mortes": { value: "deaths", tooltip: "Média de Mortes do time por partida", sorter: (a, b) => sortOrder ? a.deaths - b.deaths : b.deaths - a.deaths },
        "Torres": { value: "towers", tooltip: "Média de Torres do destruídas pelo time por partida", sorter: (a, b) => sortOrder ? a.towers - b.towers : b.towers - a.towers },
        "Total Ouro": { value: "c.gold", tooltip: "Média do Total de Ouro por partida", sorter: (a, b) => sortOrder ? a["c.gold"].localeCompare(b["c.gold"]) : b["c.gold"].localeCompare(a["c.gold"]) },
        "Total Abates": { value: "c.kills", tooltip: "Média do Total de Abates por partida", sorter: (a, b) => sortOrder ? a["c.kills"] - b["c.kills"] : b["c.kills"] - a["c.kills"] },
        "Total Larvas": { value: "c.grubs", tooltip: "Média do Total de Vastilarvas por partida", sorter: (a, b) => sortOrder ? a["c.grubs"] - b["c.grubs"] : b["c.grubs"] - a["c.grubs"] },
        "Total Torres": { value: "c.towers", tooltip: "Média do Total de Torres por partida", sorter: (a, b) => sortOrder ? a["c.towers"] - b["c.towers"] : b["c.towers"] - a["c.towers"] },
        "Total Dragões": { value: "c.dragons", tooltip: "Média do Total de Dragões por partida", sorter: (a, b) => sortOrder ? a["c.dragons"] - b["c.dragons"] : b["c.dragons"] - a["c.dragons"] },
        "Total Barões": { value: "c.nashors", tooltip: "Média do Total de Barões por partida", sorter: (a, b) => sortOrder ? a["c.nashors"] - b["c.nashors"] : b["c.nashors"] - a["c.nashors"] },
        "Torre > 11.5": { value: "%towers > 11.5", tooltip: "Porcentagem de vezes que caem mais de 11.5 torres por partida", sorter: (a, b) => sortOrder ? parseFloat(a["%towers > 11.5"]) - parseFloat(b["%towers > 11.5"]) : parseFloat(b["%towers > 11.5"]) - parseFloat(a["%towers > 11.5"]) },
        "Torre > 12.5": { value: "%towers > 12.5", tooltip: "Porcentagem de vezes que caem mais de 12.5 torres por partida", sorter: (a, b) => sortOrder ? parseFloat(a["%towers > 12.5"]) - parseFloat(b["%towers > 12.5"]) : parseFloat(b["%towers > 12.5"]) - parseFloat(a["%towers > 12.5"]) },
        "Drag > 4.5": { value: "%dragons > 4.5", tooltip: "Porcentagem de vezes que abatem mais de 4.5 dragões por partida", sorter: (a, b) => sortOrder ? parseFloat(a["%dragons > 4.5"]) - parseFloat(b["%dragons > 4.5"]) : parseFloat(b["%dragons > 4.5"]) - parseFloat(a["%dragons > 4.5"]) },
        "Drag > 5.5": { value: "%dragons > 5.5", tooltip: "Porcentagem de vezes que abatem mais de 5.5 dragões por partida", sorter: (a, b) => sortOrder ? parseFloat(a["%dragons > 5.5"]) - parseFloat(b["%dragons > 5.5"]) : parseFloat(b["%dragons > 5.5"]) - parseFloat(a["%dragons > 5.5"]) },
        "Barão > 1.5": { value: "%nashors > 1.5", tooltip: "Porcentagem de vezes que abatem mais de 1.5 barões por partida", sorter: (a, b) => sortOrder ? parseFloat(a["%nashors > 1.5"]) - parseFloat(b["%nashors > 1.5"]) : parseFloat(b["%nashors > 1.5"]) - parseFloat(a["%nashors > 1.5"]) },
        "Inib > 1.5": { value: "%inhib > 1.5", tooltip: "Porcentagem de vezes que destroem mais de 1.5 inibidores por partida", sorter: (a, b) => sortOrder ? parseFloat(a["%inhib > 1.5"]) - parseFloat(b["%inhib > 1.5"]) : parseFloat(b["%inhib > 1.5"]) - parseFloat(a["%inhib > 1.5"]) },
    }

    const sortTable = (column, overrideSort) => {
        if(columns[column] == undefined)
            return;

        let newData = data;

        if(overrideSort == undefined)
        {
            if(sortBy != column)
            {
                setSortBy(column);
                setSortOrder(false);
            } else {
                setSortOrder(!sortOrder);
            }

            newData = jsonData.sort(columns[column].sorter);
        } else {
            let oldSortOrder = sortOrder;

            setSortOrder(overrideSort)
            newData = jsonData.sort(columns[column].sorter);
            setSortOrder(oldSortOrder)
        }

        setData(newData);
    };


    useEffect(() => {
        if(jsonData.length > 0)
        {
            if(sortBy == "")
            {
                sortTable("Time");
            } else {
                sortTable("Time", sortOrder);
            }
        }

    }, [sortBy, jsonData]);

    const handleEvent = (event) => {
        sortTable(event.target.innerText);
    }

    const handleColumnColor = (key) => {
        setSelectedColumn(key);
    }

    const handleFilter = (e) => {
        if(e.target.value == "")
            return;

        if(leagues.includes(e.target.value))
            return;

        setLeagues([...leagues, e.target.value]);
        e.target.value = e.target[0].value;
    }

    const removeFilter = (e) => {
        if(locked)
            return;

        setLeagues(leagues.filter(l => l != e));
    }

    return (
        <div className='div-content bet'>
            <div className='div-content-2'>
                <div className="wrap-table">
                    <div className='filters'>
                        {leagues != undefined ? leagues.map((league) => (
                            <div className='filter' onClick={() => removeFilter(league)}>{league.replaceAll("_", " ")}</div>
                        )) : ""}
                        <select onChange={handleFilter} disabled={locked}>
                            {leagueList != undefined ? leagueList.map((leagueName) => (
                                <option value={leagueName} disabled={leagues.includes(leagueName)}>{leagueName.replaceAll("_", " ")}</option>
                            )) : ""}
                        </select> 
                    </div>
                    <div className="table" onMouseLeave={() => handleColumnColor(-1)}>
                        <div className="row header" onMouseEnter={() => handleColumnColor(-1)}>
                            {Object.keys(columns).map((column) => (
                                <div className="cell" onMouseUp={handleEvent}>{columns[column].tooltip != "" ? (<Tooltip content={columns[column].tooltip}>{column}</Tooltip>) : column}</div>
                            ))}
                        </div>
                        {data != undefined ? (
                            Object.entries(data).map(([key, value]) => (
                                <div className="row">
                                    {Object.keys(columns).map((column, index) => (
                                        <div className={"cell" + (selectedColumn == index ? " selected" : "")} onMouseEnter={() => handleColumnColor(index)}>{value[columns[column].value]}</div>
                                    ))}
                                </div>
                            ))
                        ) : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};

function UpdateUrl(leagues)
{
    let params = {};
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    let removeLastSymbol = false;

    if(leagues.length > 1 || (leagues.length == 1 && leagues[0] != ""))
        params["leagues"] = leagues.join(",");

    if(Object.keys(params).length > 0)
    {
        newurl += "?"
    
        for(let param in params)
        {
            newurl += `${param}=${params[param]}&`;
            removeLastSymbol= true;
        }
    }
    
    if(removeLastSymbol)
        newurl = newurl.substring(0, newurl.length -1);
    
    window.history.pushState({path:newurl}, '', newurl);
}

export default BettingStats;