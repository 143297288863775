import React, { useState, useEffect } from "react";
import Spectator from '../Spectator';

const PlayerRank = ({accountData, allPlayers, riotProps, specOrdered, playerName}) => {
    const [hover, setHover] = useState(false);
    const [element, setElement] = useState(null);
    const isAnimating = useAnimateOnScroll(element);

    let rankName = "";
    let rankTier = "";
    let lp = 0;

    if(accountData != null)
    {
        rankName = accountData.tier.toLowerCase();
        rankName = rankName.charAt(0).toUpperCase() + rankName.slice(1);

        rankTier = accountData.rank;
        lp = accountData.leaguePoints;


        if(playerName == "Gordox")
        {
            rankName = "Café com Leite";
            rankTier = "";
            lp = "∞";
        }
    }

    return (
        <div className="div-rank" onMouseOver={() => {setHover(true);}} onMouseOut={() => {setHover(false);}}>
            <img width={"30%"} src={rankName != "" ? `https://victti-dev.com.br/public/images/rank/${rankName}.webp` : "https://victti-dev.com.br/public/images/rank/Unranked.png"} />
            <span ref={(ref) => {setElement(ref)}} className={accountData.spectator != undefined && isAnimating ? "ingame" : ""}>{accountData.gameName}<span className="account-tagLine">{"#" + accountData.tagLine}</span><br/>{rankName != "" ? `${rankName} ${rankTier} - ${lp} PDL` : "Unranked"}</span>
            {riotProps != undefined ? <Spectator isHovering={hover} specData={accountData.spectator} allPlayers={allPlayers} riotProps={riotProps} specOrdered={specOrdered} /> : ""}
        </div>
    );
};

const options = {
    root: null,
    rootMargin: '100px',
    threshold: 0
}

function useAnimateOnScroll(element: HTMLElement | null) {
    const [isAnimating, setIsAnimating] = useState(false);
  
    useEffect(() => {
      if (!element) return;
  
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) setIsAnimating(true);
        else setIsAnimating(false);
      }, options);
  
      observer.observe(element);
    }, [element]);
  
    return isAnimating;
}
 
export default PlayerRank;