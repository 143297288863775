import React, {useEffect, useState} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import "../styles/multistream.scoped.css";
import useScript from '../utils/useScript';
import useStyle from '../utils/useStyle';

const MultiStream = () => {
    const { stream1, stream2, stream3, stream4, stream5, stream6, stream7, stream8, stream9 } = useParams();
    const [focusStream, setFocusStream] = useState(-1);
    const [keyLock, setKeyLock] = useState(false);
    const [screenLock, setScreenLock] = useState(false);

    // Talvez uma overlay tipo flowics?
    const [searchParams, setSearchParams] = useSearchParams();
    let overlayURL = searchParams.get("overlay");
    overlayURL = isValidUrl(overlayURL) ? overlayURL : undefined;

    // Usado pra checar se os script carregaram
    const [twitchScript, setTwitchScript] = useState(false);
    const [youtubeScript, setYoutubeScript] = useState(false);
    const [customEmbedScript, setCustomEmbedScript] = useState(false);
    const [hlsScript, setHlsScript] = useState(false);
    const [players, setPlayers] = useState([]);

    // Usado pra atualizar o componente pra recuperar o foco na janela
    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        document.title = "MultiStream";
    });

    // Limpa tela se necessário
    useEffect(() => {
        let html = document.getElementsByTagName("html")[0];
        if(html)
            html.style = "height: 100%";

        let body = document.getElementsByTagName("body")[0];
        if(body)
            body.style = "height: 100%";

        let root = document.getElementById("root");
        if(root)
            root.style = "height: 100%";

        let nav = document.getElementsByTagName("nav")[0];
        if(nav)
            nav.style = "display: none";
    });

    useScript("https://player.twitch.tv/js/embed/v1.js");
    useScript("https://www.youtube.com/s/player/590f65a6/www-widgetapi.vflset/www-widgetapi.js"); // Talvez esse link precise ser atualizado de tempos em tempos
    useScript("https://www.victti-dev.com.br/public/js/CustomEmbedPlayer.js");
    useScript("https://cdn.plyr.io/3.7.8/plyr.js");
    useStyle("https://cdn.plyr.io/3.7.8/plyr.css");
    useScript("https://cdn.jsdelivr.net/npm/hls.js@1");

    let streams = [stream1, stream2, stream3, stream4, stream5, stream6, stream7, stream8, stream9];
    streams = streams.filter(stream => stream != undefined);

    // Cria os players
    useEffect(() => {
        if(!twitchScript || !youtubeScript || !customEmbedScript || !hlsScript || players.length > 0)
            return;

        let newPlayers = [];
        for(let channel of streams)
        {
            let resolution = { width: 1920, height: 1080 };

            if(channel.startsWith("youtube@"))
            {
                let videoId = channel.replace("youtube@", "");
                let playerId = `youtube-embed-${videoId}-frame-${newPlayers.length}`;

                newPlayers.push(new window.YT.Player(playerId, {
                    width: resolution.width,
                    height: resolution.height,
                    videoId,
                    events: {
                        'onReady': ytPlayerReady
                    }
                }));
                continue;
            }

            // https://liveshare.huya.com/iframe/lpl

            // view-source:https://www.huya.com/lpl
            // hyPlayerConfig
            // json.data[0].gameStreamInfoList

            // https://al-game.flv.huya.com/src/78941969-2559461593-10992803837303062528-2693342886-10057-A-0-1-imgplus.flv?wsSecret=c1709b513140de73b43582d2f5f349e0&wsTime=669cda26&seqid=2781940572287&ctype=huya_webh5&ver=1&sphdcdn=al_7-tx_3-js_3-ws_7-bd_2-hw_2&sphdDC=huya&sphd=264_*-265_*&exsphd=264_500,264_2000,264_4000,264_8000,264_10000,264_15000,&fs=gctex&ratio=500&dMod=mseh-33&sdkPcdn=1_1&u=1060324912099&t=100&sv=2407100656&sdk_sid=1721555407197&a_block=0
            if(channel.startsWith("huya@"))
            {
                let channelName = channel.replace("huya@", "");
                let playerId = `huya-embed-${channelName}`;

                // newPlayers.push(new window.HPlayer(playerId, {
                //     width: resolution.width,
                //     height: resolution.height,
                //     channelName,
                //     url: `https://liveshare.huya.com/iframe/${channelName}`
                // }));
                // continue;

                newPlayers.push(new window.CEPlayer(playerId, {
                    width: resolution.width,
                    height: resolution.height,
                    channelName,
                    url: `https://liveshare.huya.com/iframe/${channelName}`
                }));

                continue;
            }

            // https://play.afreecatv.com/y1026/embed?mutePlay=true
            if(channel.startsWith("afreecatv@"))
            {
                let channelName = channel.replace("afreecatv@", "");
                let playerId = `afreecatv-embed-${channelName}`;

                newPlayers.push(new window.CEPlayer(playerId, {
                    width: resolution.width,
                    height: resolution.height,
                    channelName,
                    url: `https://play.afreecatv.com/${channelName}/embed?mutePlay=true`
                }));

                continue;
            }

            // bilibili
            // https://api.live.bilibili.com/xlive/web-room/v2/index/getRoomPlayInfo?room_id=6&no_playurl=0&mask=1&qn=0&platform=web&protocol=0,1&format=0,1,2&codec=0,1,2&dolby=5&panorama=1
            if(channel.startsWith("bilibili@"))
            {
                let channelName = channel.replace("bilibili@", "");
                let playerId = `bilibili-embed-${channelName}`;

                newPlayers.push(new window.BLPlayer(playerId, {
                    width: resolution.width,
                    height: resolution.height,
                    channelName,
                    url: `https://api.live.bilibili.com/xlive/web-room/v2/index/getRoomPlayInfo?room_id=${channelName}&protocol=0,1&format=0,1,2&codec=0,1,2&qn=10000&platform=web&ptype=8&dolby=5&panorama=1`
                }));

                continue;
            }

            // chzzk
            // https://chzzk.naver.com/live/c68b8ef525fb3d2fa146344d84991753
            // https://gist.github.com/zeroday0619/2d03e11bd9e0a76e39915ade887058d5
            // https://api.chzzk.naver.com/service/v2/channels/c68b8ef525fb3d2fa146344d84991753/live-detail
            // https://nochev.github.io/hls.js/docs/html/

            let playerId = `twitch-embed-${channel}`;
            newPlayers.push(new window.Twitch.Player(playerId, {
                width: resolution.width,
                height: resolution.height,
                channel,
                parent: [window.location.hostname],
                muted: true
              }));
        };
        setPlayers(newPlayers);
    }, [twitchScript, youtubeScript, customEmbedScript, hlsScript]);

    // Verifica se o script da Twitch carregou na página
    if(!twitchScript && window.Twitch != undefined)
        setTwitchScript(true);

    // Verifica se o script do YouTube carregou na página
    if(!youtubeScript && window.YT != undefined)
        setYoutubeScript(true);

    // Verifica se o script do Custom Embed Player carregou na página
    if(!customEmbedScript && window.CEPlayer != undefined)
        setCustomEmbedScript(true);

    // Verifica se o script do HLS carregou na página
    if(!hlsScript && window.Hls != undefined)
        setHlsScript(true);

    // Seta qual player aparecer na tela e o miniplayer
    const handleKeyPress = (event) => {
        if(event.key == "l")
        {
            setScreenLock(!screenLock);
            return;
        }

        if(keyLock)
            return;

        if(!/^[0-9]$/i.test(event.key) || (event.key != 0 && event.key == miniStream + 1))
        {
            handleMiniPlayer(event);
            return;
        }

        let num = event.key;
        num = num > streams.length ? streams.length : num;
        num -= 1;

        if(focusStream != -1)
            mutePlayer(players[focusStream])
        if(num != -1)
            unMutePlayer(players[num]);

        setFocusStream(num);
    }

    // anima o miniplayer
    const [miniStream, setMiniStream] = useState(-1); // seta a classe do mini player com o tempo de transição, borda etc
    const [miniStreamIn, setMiniStreamIn] = useState(-1); // seta a classe do mini player com o tamanho de entrada
    const [miniStreamOut, setMiniStreamOut] = useState(-1); // seta a classe do mini player com o tamanho de saída
    const [miniStreamFull, setMiniStreamFull] = useState(-1); // seta a classe do mini player com o tamanho fullscreen
    const [miniStreamAnimate, setMiniStreamAnimate] = useState(-1); // seta a classe do mini player com o tamanho fullscreen 

    const handleMiniPlayer = (event) => {
        let previousMini = miniStream;
        let inFlag = previousMini == -1;
        let outFlag = false;
        let fullFlag = /^[0-9]$/i.test(event.key);

        let newMini = GetMiniplayerNum(event.key);

        // Evita problemas tentando colocar a stream focada atual no mini player
        if(newMini == focusStream && !fullFlag)
            return;

        newMini = newMini == previousMini && !fullFlag ? -1 : newMini;

        outFlag = newMini == -1;

        if(inFlag || outFlag || fullFlag)
            setMiniStreamAnimate(inFlag || fullFlag ? newMini : outFlag ? previousMini : -1);

        // Evita problemas tentando trocar a stream focada ou do mini player
        setKeyLock(true);

        if(inFlag)
        {
            setMiniStream(newMini);
            if(!outFlag)
            {
                setMiniStreamOut(-1);
            }
            setMiniStreamIn(newMini);
            setTimeout(() => {
                setKeyLock(false);
            }, 800);
        }
        
        if(outFlag)
        {
            if(!inFlag)
            {
                setMiniStreamIn(-1);
            }
            setMiniStreamOut(previousMini);
            setTimeout(() => {
                setMiniStream(-1);
                setKeyLock(false);
            }, 800);
        }

        if(fullFlag)
        {
            setMiniStreamIn(-1);
            setMiniStreamOut(-1);
            
            setMiniStreamFull(newMini);
            setTimeout(() => {
                if(focusStream != -1)
                    mutePlayer(players[focusStream]);

                setFocusStream(newMini);

                if(newMini != -1)
                    unMutePlayer(players[newMini]);

                setMiniStream(-1);
                setMiniStreamFull(-1);
                setMiniStreamAnimate(-1);
                setKeyLock(false);
            }, 750);
        }

        if(!inFlag && !outFlag && !fullFlag)
        {
            setMiniStreamAnimate(-1);
            setMiniStreamIn(newMini);
            setMiniStream(newMini);
            setKeyLock(false);
            setTimeout(() => {
                setMiniStreamAnimate(newMini);
            }, 100);
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return  () => window.removeEventListener("keydown", handleKeyPress);
    }, [focusStream, miniStream, keyLock, players, screenLock]);

    // Atualiza o componente pra dar foco na janela
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1500);
        return () => {
            clearInterval(interval);
        };
    }, []);

    // A janela precisa estar "focada" pra poder trocar de stream com os botões
    window.focus();

    // Configura os players do YouTube quando eles carregam
    const ytPlayerReady = (event) => {
        event.target.playVideo();
        event.target.mute();
        event.target.setPlaybackQuality("hd1080");

        let iframeIdSplit = event.target.g.id.split("-");
        let playerNum = Number(iframeIdSplit[iframeIdSplit.length - 1]);

        // Quando se usa uma variável de "state" dentro do "set State", vai ser usado o "state" mais atual sempre
        setPlayers(players => Object.assign([], players, {[playerNum]: event.target}));
    }

    return (
        <>
            <div className='multistream'>
                {streams.length > 0 ? streams.map((stream, index) => (
                    <div className={`player` + (focusStream != -1 && focusStream == index ? " selected" : "") + (focusStream == -1 ? "" : focusStream != index && miniStream != -1 && miniStream == index ? " mini" + (miniStreamIn == index ? " in" : miniStreamOut == index ? " out" : miniStreamFull == index ? " full" : "") : "") + (miniStreamAnimate == index ? " animate" : "")} style={focusStream != -1 && focusStream != index ? { flex: 0, width: 0, height: 0, border: 0 } : {}} id={`${!stream.includes("@") ? "twitch" : stream.split("@")[0]}-embed-${!stream.includes("@") ? stream : stream.split("@")[1]}`}>{stream.includes("@") ? stream.startsWith("youtube@") ? (<div id={`youtube-embed-${stream.replace("youtube@", "")}-frame-${index}`}></div>) : "" : ""}</div>
                )) : (<span>Você precisa por pelo menos 1 stream no seguinte formato:<br />{`${window.location.host}/multistream/baiano/cblol/youtube@Crw-WY0J_gA`}<br /><br />Hotkeys:<br />0: Mostra todas as streams<br />1-9: Alterna as streams para fullscreen<br />Q/W/E/R/T: Mostra/Esconde um PIP com as streams 1-5</span>)}
            </div>
            {screenLock ? (<div className='screen-lock'></div>) : ""}
            {overlayURL != undefined ? (<iframe id='iframe-overlay' frameBorder={0} src={overlayURL} />) : ""}
        </>

    );
};

function GetMiniplayerNum(key)
{
    switch(key)
    {
        case "q":
            return 0;
        case "w":
            return 1;
        case "e":
            return 2;
        case "r":
            return 3;
        case "t":
            return 4;
        default:
            return !/^[0-9]$/i.test(key) ? -1 : key - 1;
    }
}

// Se o setMuted existir, é um player da Twitch, se não, é do YouTube
function unMutePlayer(player)
{
    if(player.setMuted != undefined)
    {
        player.setMuted(false);
    } else if (player.unMute != undefined)
    {
        player.unMute();
    } else if(player._hlsPlayer != undefined)
    {
        player._video.muted = false;
    } else {
        // Não da pra mutar/desmutar
    }
}

// Se o setMuted existir, é um player da Twitch, se não, é do YouTube
function mutePlayer(player)
{
    console.log(player)
    if(player.setMuted != undefined)
    {
        player.setMuted(true);
    } else if (player.mute != undefined)
    {
        player.mute();
    } else if(player._hlsPlayer != undefined)
    {
        player._video.muted = true;
    } else {
        // Não da pra mutar/desmutar
    }
}

function isValidUrl(urlString)
{
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export default MultiStream;