import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {isMobile, BrowserView, MobileView} from 'react-device-detect';
import OficialGamesWidget from "../components/OficialGamesWidget"
import {DesktopPlayerRow, MobilePlayerRow} from "../components/PlayerRow";
import BackToTop from "../components/BackToTop";
import "../styles/lolpros.css";
import "../styles/lolpros-backgrounds.css";
import "../styles/lolpros-teams.css";
import "../styles/lolpros-mobile.css";
import GetRiotProps from "../utils/leagueStuff";

const Lolpros = (props) => {
    const queryParameters = new URLSearchParams(window.location.search)
    const searchParam = queryParameters.get("search") != undefined ? queryParameters.get("search") : "";
    const lanesParam = queryParameters.get("lanes") != undefined ? queryParameters.get("lanes").split(",") : [];
    const ingameParam = queryParameters.get("ingame") != undefined ? queryParameters.get("ingame") == "true" : false;

    const [riotProps, setRiotProps] = useState(undefined)
    const [backendData, setBackendData] = useState(undefined);
    const [specOrdered, setSpecOrdered] = useState(undefined);
	  const specDataLoaded = useRef(false);
    const [filter, setFilter] = useState({infiniteScroll: 25, isAnyActive: (searchParam != "" || lanesParam.length != 0 || ingameParam), search: searchParam.toUpperCase(), lanes: lanesParam, inGame: ingameParam});

    let { region } = useParams();

    useEffect(() => {
      document.title = "Lolpros";

      GetRiotProps("lolpros", props.patchNumber).then(
        async data => {
          setRiotProps(data)
        }
      )
    }, []);

    useEffect(() => {
      fetch("/api/lolpros/players/" + region)
      .then(response => response.json())
      .then(async data => {
          setBackendData(data)
        }
      )

      const playersInterval = setInterval(() => {
        fetch("/api/lolpros/players/" + region)
          .then(response => response.json())
          .then(async data => {
            specDataLoaded.current = false;
            setBackendData(data)
          }
        );
      }, 60000);

      return () => {
        clearInterval(playersInterval);
      };
    }, []);

    let allPlayers = {};
    let allLiveGames = {}
    let playingNow = 0;

    if(backendData !== 'undefined')
    {
      for(let entry in backendData)
      {
        let item = backendData[entry];

        if(item.accounts == undefined)
          continue;

        for(let account of item.accounts)
        {
          allPlayers[account.gameName + "#" + account.tagLine] = {name: item.name, team: item.team};

          if(account.spectator != undefined)
          {
            playingNow += 1;

            allLiveGames[account.spectator.gameId] = account.spectator;
          }
        }
      }
    }

    useEffect(() => {
		if(!specDataLoaded.current)
		{
			let output = {}
			const checkFinished = (obj) => {
				let finished = true;

				for(let gameId in obj)
				{
					for(let teamId in obj[gameId])
					{
						if(obj[gameId][teamId] == undefined)
							finished = false;
					}
				}
		
				if(finished)
				{
					setSpecOrdered(obj);
					specDataLoaded.current = true;
				}
			}

			for(let gameId in allLiveGames)
			{
				let obj = {}
		
				if(gameId == "undefined")
					continue;

				for(let participant of allLiveGames[gameId].participants)
				{
					if(obj[participant.teamId] == undefined)
						obj[participant.teamId] = {output: {}, ids: ""}
		
					obj[participant.teamId].ids += obj[participant.teamId].ids != "" ? "," + participant.championId : participant.championId;
				}

				output[gameId] = {}

				for(let teamId in obj)
				{
					output[gameId][teamId] = undefined;
				}
	  
				for(let teamId in obj)
				{
					if(specOrdered != undefined && specOrdered[gameId] != undefined && specOrdered[gameId][teamId] != undefined)
					{
						output[gameId][teamId] = specOrdered[gameId][teamId];
						checkFinished(output);
						continue;
					}
					
					const fetchData = async () => {
						output[gameId][teamId] = await fetch("/api/v1/league/predict-lanes/" + obj[teamId].ids).then(response => response.json());
						checkFinished(output);
					}
		
					fetchData();
				}
			}
		}
    }, [allLiveGames]);

    //#region Filters
    useEffect(() => {
      function handleScroll() {
        const newScrollPercentage = getScrollPercent();
    
        if(newScrollPercentage > 95 && !filter.isAnyActive)
        {
        let newFilter = JSON.parse(JSON.stringify(filter));
    
        newFilter.infiniteScroll += 25;
      
        setFilter(newFilter);
        }
      }
    
      window.addEventListener("scroll", handleScroll, true);
    
      // clean up event listener when component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll, true);
      }
    }, [filter]);

    const filterInput = (event) => {
		let newFilter = JSON.parse(JSON.stringify(filter));
		let searchValue = event.target.value;

		newFilter.search = event.target.value;
		newFilter.isAnyActive = newFilter.search != "" || newFilter.lanes.length != 0 || newFilter.inGame;

		setFilter(newFilter);
		UpdateUrl(newFilter);
    }

    const filterLane = (event) => {
      let newFilter = JSON.parse(JSON.stringify(filter));

      if(event.type == "click")
      {
        let index = newFilter.lanes.indexOf(event.target.value);
        if (index > -1) {
          newFilter.lanes.splice(index, 1);
        } else {
          newFilter.lanes.push(event.target.value);
        }
      } else if(event.type == "change")
      {
        newFilter.lanes = [];

        if(event.target.selectedIndex > 1)
          newFilter.lanes.push(event.target.value);
      }

	    newFilter.isAnyActive = newFilter.search != "" || newFilter.lanes.length != 0 || newFilter.inGame;

      setFilter(newFilter);
      UpdateUrl(newFilter);
    }

    const filterInGame = (event) => {
      let newFilter = JSON.parse(JSON.stringify(filter));

      newFilter.inGame = !newFilter.inGame;
	    newFilter.isAnyActive = newFilter.search != "" || newFilter.lanes.length != 0 || newFilter.inGame;

      setFilter(newFilter);
      UpdateUrl(newFilter);
    }
    //#endregion

    return (
      <div className={`lolpros-body ${region}`}>
        <OficialGamesWidget leagueId={"98767991332355509,105549980953490846"} />
        <div className="div-search">
          {playingNow > 0 ? <span className="span-playing-now ingame">~{playingNow} jogadores em partida...</span> : ""}
          <input type="search" id="searchBar" onChange={filterInput} placeholder="Procurar um nick ou jogador..." title="Digite um nome" value={filter.search} />
          <div className="div-filter-lanes">
            <BrowserView style={{display: "inline"}}>
              <button type="button" onClick={filterLane} className={`button-filter-lane ${filter.lanes.includes("TOP") ? "selected" : ""}`} value="TOP">Topo</button>
              <button type="button" onClick={filterLane} className={`button-filter-lane ${filter.lanes.includes("JUNGLE") ? "selected" : ""}`} value="JUNGLE">Caçador</button>
              <button type="button" onClick={filterLane} className={`button-filter-lane ${filter.lanes.includes("MID") ? "selected" : ""}`} value="MID">Meio</button>
              <button type="button" onClick={filterLane} className={`button-filter-lane ${filter.lanes.includes("ADC") ? "selected" : ""}`} value="ADC">Atirador</button>
              <button type="button" onClick={filterLane} className={`button-filter-lane ${filter.lanes.includes("SUPPORT") ? "selected" : ""}`} value="SUPPORT">Suporte</button>
              <button type="button" onClick={filterLane} className={`button-filter-lane ${filter.lanes.includes("COACH") ? "selected" : ""}`} value="COACH">Coach</button>
              <span className="filter-lane-divider"></span>
            </BrowserView>
            <MobileView>
              <select id="filter-lane" onChange={filterLane}>
                  <option value="" disabled selected={filter.lanes.length == 0} hidden>Filtrar uma Rota...</option>
                  <option value="">-</option>
                  <option value="TOP" selected={filter.lanes.includes("TOP")}>Topo</option>
                  <option value="JUNGLE" selected={filter.lanes.includes("JUNGLE")}>Caçador</option>
                  <option value="MID" selected={filter.lanes.includes("MID")}>Meio</option>
                  <option value="ADC" selected={filter.lanes.includes("SUPPORT")}>Atirador</option>
                  <option value="SUPPORT" selected={filter.lanes.includes("SUPPORT")}>Suporte</option>
                  <option value="COACH" selected={filter.lanes.includes("COACH")}>Coach</option>
              </select>
            </MobileView>
            <button type="button" onClick={filterInGame} className={`button-filter-lane ${filter.inGame ? "selected" : ""}`} value="INGAME">Em partida</button>
          </div>
        </div>
        <BrowserView>
          {GetDesktopView(backendData, allPlayers, riotProps, specOrdered, filter)}
        </BrowserView>
        <MobileView>
          {GetMobileView(backendData, allPlayers, riotProps, specOrdered, filter)}
        </MobileView>
        <BackToTop />
      </div>
    );
};

function GetDesktopView(backendData, allPlayers, riotProps, specOrdered, filter)
{
  return(
    <table>
      <tr>
        <th style={{"width" : "5%"}}>#</th>
        <th style={{"width" : "10%"}}>ROTA</th>
        <th style={{"width" : "13%"}} className="align-left">JOGADOR</th>
        <th>ELO</th>
        <th>WINRATE</th>
        <th>SOCIAL</th>
      </tr>
        {(typeof backendData === 'undefined') ? (
          <tr><td colSpan={6}><div><img className="loading-icon" src="https://victti-dev.com.br/public/images/loading.webp" /></div></td></tr>
        ): (
			backendData.map((player, index) => (
				((index <= filter.infiniteScroll || filter.isAnyActive) &&
				(filter.search == "" || player.name.toUpperCase().indexOf(filter.search.toUpperCase()) > -1 || player.accounts.filter(a => a.summonerName.toUpperCase().indexOf(filter.search.toUpperCase()) > -1).length > 0) &&
				(filter.lanes.length == 0 || filter.lanes.includes(player.role)) &&
				(!filter.inGame || (filter.inGame && player.accounts.filter(a => a.spectator != undefined).length > 0))) ? (
					<DesktopPlayerRow key={`player-${index}`} playerIndex={index} player={player} allPlayers={allPlayers} riotProps={riotProps} specOrdered={specOrdered} />
				) : ""
          	))
        )}
    </table>
  );
}

function GetMobileView(backendData, allPlayers, riotProps, specOrdered, filter)
{
  return(
    <div id="fake-table">
		{(typeof backendData === 'undefined') ? (
			<div><img className="loading-icon" src="https://victti-dev.com.br/public/images/loading.webp" /></div>
		): (
			backendData.map((player, index) => (
				((index <= filter.infiniteScroll || filter.isAnyActive) &&
				(filter.search == "" || player.name.toUpperCase().indexOf(filter.search.toUpperCase()) > -1 || player.accounts.filter(a => a.summonerName.toUpperCase().indexOf(filter.search.toUpperCase()) > -1).length > 0) &&
				(filter.lanes.length == 0 || filter.lanes.includes(player.role)) &&
				(!filter.inGame || (filter.inGame && player.accounts.filter(a => a.spectator != undefined).length > 0))) ? (
					<MobilePlayerRow key={`player-${index}`} playerIndex={index} player={player} allPlayers={allPlayers} riotProps={riotProps} specOrdered={specOrdered} />
				) : ""
			))
		)}
    </div>
  );
}

function UpdateUrl(filters)
{
  let params = {};
  let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
  let removeLastSymbol = false;

  if(filters.search != "")
    params["search"] = filters.search;

  if(filters.lanes.length != 0)
  {
    params["lanes"] = "";
    for(let lane of filters.lanes)
    {
      params["lanes"] += lane + ",";
    }

    params["lanes"] = params["lanes"].substring(0, params["lanes"].length -1);
  }

  if(filters.inGame)
    params["ingame"] = true;

  if(filters.worlds)
    params["worlds"] = true;

  if(filters.regions != undefined && filters.regions.length != 0)
  {
    params["regions"] = "";
    for(let lane of filters.regions)
    {
      params["regions"] += lane + ",";
    }

    params["regions"] = params["regions"].substring(0, params["regions"].length -1);
  }

  if(Object.keys(params).length > 0)
  {
    newurl += "?"

    for(let param in params)
    {
      newurl += `${param}=${params[param]}&`;
      removeLastSymbol= true;
    }
  }

  if(removeLastSymbol)
    newurl = newurl.substring(0, newurl.length -1);

  window.history.pushState({path:newurl}, '', newurl);
}

function getScrollPercent() {
	var h = document.documentElement,
	  b = document.body,
	  st = "scrollTop",
	  sh = "scrollHeight";
  
	var scrollPercent = Math.round(
	  ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
	);
	//get the percentage of scroll
  
	return isNaN(scrollPercent) ? "" : scrollPercent;
}
 
export default Lolpros;