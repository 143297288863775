import React, { useState, useEffect } from "react";
import Match from "./Match";

const MatchHistory = ({isMobile, isMhExpanded, player, riotProps}) => {
    const [matchIndex, setMatchIndex] = useState(0);
    const [oldMatchIndex, setOldMatchIndex] = useState(-1);
    const [mostPlayedData, setMostPlayedData] = useState(undefined);
    const [mostWinrateData, setMostWinrateData] = useState(undefined);
    const [matchHistoryData, setMatchHistoryData] = useState(undefined);

	useEffect(() => {
        if(isMhExpanded)
        {
            if(matchHistoryData == undefined || matchIndex != oldMatchIndex)
            {
                fetch(`/api/v1/league/lolprosstats/${player.name}/${riotProps.pageName == "bootcamp" ? "bootcamp" : "lolpros"}/${matchIndex}`).then(
                    response => response.json()
                ).then(
                    async data => {
                        setMatchHistoryData(data);
                        setMostPlayedData(data.mp);
                        setMostWinrateData(data.mwr);

                        setOldMatchIndex(matchIndex);
                    }
                )
            }
        }
	}, [matchHistoryData, isMhExpanded, player, matchIndex]);

    const changePage = (event) => {
        setMatchHistoryData(undefined);

        let newIndex = event.target.classList.contains("button-right") ? matchIndex + 5 : matchIndex - 5;
        newIndex = Math.max(0, newIndex);
        setMatchIndex(newIndex);
    }

    const TagRow = isMobile ? "div" : "tr";
    const TagData = isMobile ? "div" : "td";

    return (isMhExpanded ? (
        <TagRow id={`player-row-data-${player.name}`} className={`player-row-data`} matchindex={matchIndex}>
            <TagData className="td-player-data-container" colSpan={6}>
                {matchHistoryData == undefined && mostPlayedData == undefined && mostWinrateData == undefined ? (
                    <div>
                        <img className="loading-icon" src="https://victti-dev.com.br/public/images/loading.webp" />
                    </div>
                ) : (
                    <div className="div-player-data-container">
                        <div className="player-data-top">
                            <div className="most-played">
                                <div className="data-header">Mais jogados</div>
                                <div className="champions-data">
                                    {mostPlayedData.map(entry => (
                                        <div key={`mp-${entry.c}`} className="champion-data">
                                            <img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/champion/${entry.c}.png`} title={entry.c} />
                                            <div className="champion-data-stats">{entry.g} Jogos ({entry.wr}%)</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="best-winrate">
                                <div className="data-header">Maior winrate por jogos</div>
                                <div className="champions-data">
                                    {mostWinrateData.map(entry => (
                                        <div key={`wr-${entry.c}`} className="champion-data">
                                            <img src={`https://ddragon.leagueoflegends.com/cdn/${riotProps.patchNumber}/img/champion/${entry.c}.png`} title={entry.c} />
                                            <div className="champion-data-stats">{entry.g} Jogos ({entry.wr}%)</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="player-data-bottom">
                            <div className="data-header">Últimos 5 jogos</div>
                            <div className="match-history">
                                {matchHistoryData == undefined ? (<div><img className="loading-icon" src="https://victti-dev.com.br/public/images/loading.webp" /></div>) : (
                                    matchHistoryData.mh.map(match => (
                                        <Match key={match.id} isMobile={isMobile} match={match} riotProps={riotProps} />
                                    ))
                                )}
                                {(matchHistoryData != undefined && matchIndex != 0 ? <span className="next-button button-left" onClick={changePage}>&#8249;</span> : "")}
                                {(matchHistoryData != undefined && matchHistoryData.np != 0 ? <span className="next-button button-right" onClick={changePage}>&#8250;</span> : "")}
                            </div>
                        </div>
                    </div>
                )}
            </TagData>
        </TagRow>) : ""
    );
};
 
export default MatchHistory;