import React, { useState, useEffect} from "react";
import "../../styles/navbar.css";
 
const Navbar = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)

    const scrollDirection = useScrollDirection();

    if(isNavExpanded)
    {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return (
        <nav className={`navigation is-sticky ${!isNavExpanded ? scrollDirection : ""}`}>
          {/* <a href="/" className="brand-name">victti</a> */}
          <button className="hamburger" onClick={() => {setIsNavExpanded(!isNavExpanded);}}>
            {/* icon from heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
            <ul>
              <li>
                <a href="/">Início</a>
              </li>
              <li>
                <a href="/lolpros/br">LoLPros</a>
              </li>
              <li>
                <a href="/bootcamp">Bootcamp 2023.2</a>
              </li>
            </ul>
          </div>
        </nav>
      );
};

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};
 
export default Navbar;